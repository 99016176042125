import React from "react";
import "../assets/common.css";
import ProductCard from "../common/product-card";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import ProductCardSkeleton from "../common/productSkeleton";

import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { SecondaryButton } from "../assets/static/js/Button";

import Paginate from "./paginate";

const cookies = new Cookies();

class SearchOne extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables

    this.state = {
      limit: 21,
      offset: 0,
      total: 0,
      allDate: [],
      collection: [],
      query: "",
      products: [],
      button: false,
      notFound: false,
      filterShow: false,
      openfilter: true,
      isLoading: true,
      previousUrl: window.location.href
    };
    this._isMounted = false;
    this.parsed = {
      "sort-by": "all"
    }
    // detection language

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
  }
  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this._isMounted = true;

    // opening or closing sidebar
    this.filterToggle();

    //  fetching data
    this.fetchData();
  }
  async componentDidUpdate(prevProps) {
    const currentUrl = window.location.href;

    // Проверяем, изменился ли URL
    if (this.state.previousUrl !== currentUrl) {
      this._isMounted = true;
      this._isMounted && window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      // opening or closing sidebar
      // this.filterToggle();

      //  fetching data
      this.setState(
        {
          previousUrl: currentUrl,
          isLoading: true,
          category: [],
        },
        this.fetchData
      );
    }
  }
  // fetching data from api
  fetchData = async () => {
    // getting current url
    this.getQuery();
    this.sort = this.state.previousUrl.split("&").at(-1)
    const filterPath = this.state.previousUrl.split('/filter')[1].split('?')[0];
    const filterObject = {};
    
    if (filterPath) {
      const filters = filterPath.split("/").filter(Boolean);
      
      filters.forEach((item) => {
        // Разделяем каждый элемент по "-or-" для получения нескольких значений
        const parts = item.split("-or-");
    
        parts.forEach(part => {
          // Разделяем на ключ и значения, убирая префикс ключа в значениях
          const [key, ...valueParts] = part.split("-");
          const value = valueParts.join("-");
    
          // Добавляем значения в filterObject
          if (filterObject[key]) {
            filterObject[key].push(value);
          } else {
            filterObject[key] = [value];
          }
        });
      });
    }
    
    // Генерация строки запроса из filterObject
    const queryUrl = Object.keys(filterObject)
      .map(key => `${key}=${filterObject[key].join(",")}`)
      .join("&") + `&${this.url}`
    // const response = await axios.get(`${BaseUrl}multiple?${queryUrl}&${this.sort}`);
    const response = await axios.get(this.newUrl + "?" + this.url);

    const products = response.data.results;
    if (response.data.count === 0) {
      this._isMounted &&
        this.setState({
          notFound: true,
        });
    } else {
      this._isMounted &&
        this.setState({
          notFound: false,
        });
    }
    this._isMounted &&
      this.setState(
        {
          products,
          total: response.data.count,
          isLoading: false,
        }
      );
  };
  handleSortChange = (sort) => {
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.parsed["sort-by"] = sort;
    this.parsed.offset = 0
    this.url = this.queryString.stringify(this.parsed, {
      arrayFormat: "comma",
    });
    this.props.history.push(this.pathname + `?` + this.url);
    
  }
  // detec changes during pagination
  handlePageChange = (offset) => {
    this._isMounted && this.setState({ offset }, this.fetchData);
    this.parsed.offset = offset;
    this.offset = offset;
    this.url = this.queryString.stringify(this.parsed, {
      skipNull: true,
      arrayFormat: "comma",
    });
    this.props.history.push(this.pathname + "?" + this.url);
  };

  // getting current url and modify it
  getQuery = async () => {
    this.queryString = require("query-string");
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.pathname = this.props.location.pathname;
    if (this.parsed.offset) {
      this.offset = this.parsed.offset;
    } else {
      this.offset = this.state.offset;
    }
    if (this.parsed.limit) {
      this.url = this.queryString.stringify(this.parsed);
    } else {
      this.url =
        this.queryString.stringify(this.parsed) + `&limit=${this.state.limit}`;
    }
    // it renders three different pages depends on matching query
    if (this.parsed.query) {
      this.newUrl = BaseUrl + `searcht/${this.parsed.query}`;
      const query = `query=${this.parsed.query}`;
      axios.post(BaseUrl + "filters/", { filters: query }).catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.response);
      });
    } else if (this.parsed.Discount) {
      this.newUrl = BaseUrl + "products/discount/True";
    } else if (this.parsed.Trend) {
      this.newUrl = BaseUrl + "products/trends/True";
    } else {
      this.newUrl = BaseUrl + `multiple?` + this.url;
    }
  };
  // sending info to child component "Sidebar" it will work whenever width of window is lower than 576px
  filterToggle = () => {
    if (window.innerWidth < 576) {
      this._isMounted &&
      this.setState({ filterShow: false, openfilter: false, button: true })
    }
  };
  CloseFilter = () => {
    this._isMounted && this.setState({ filterShow: false, openfilter: false });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.filterToggle);
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const { limit, total } = this.state;
    return (
      <div className="main">
        <div className="container">
          <div className="link-div">
            {/* home page redirect button */}
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C"/>
            </svg>
            <p
              onClick={() => this.props.history.push("/")}
              className="text-sm pointer ml-2"
            >
              {t("mainPage.text")}
            </p>
            <span>&nbsp; / &nbsp;</span>
          </div>
          <div className="info pt-2">
            {/* button for opeing or closing filters */}
            {/* <SecondaryButton
              onClick={() =>
                this.setState({
                  filterShow: !this.state.filterShow,
                  openfilter: !this.state.openfilter,
                })
              }
              value={
                this.state.openfilter
                  ? t("FiltersClose.text")
                  : t("FiltersOpen.text")
              }
            />
            <h1 className="text-lg title">{t("Collections.text")}</h1> */}

              <div className="d-flex align-items-center gap-5 pointer"
                onClick={() =>
                  this.setState({
                    filterShow: !this.state.filterShow,
                    openfilter: !this.state.openfilter,
                  })
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M1.83325 4.16699H18.4999" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 8.25293H15.8117" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 12.3389H12.5859" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 16.4248H9.36013" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                </svg>
                <p style={{ color: this.state.openfilter ? "#0942C3" : "black", margin: '0 7px' }}>{t("filter.text")}</p>
               {this.state.openfilter &&( <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M1 1L9 9" stroke="#0942C3"/>
                  <path d="M1 9L9 1" stroke="#0942C3"/>
                </svg>)}
              </div>
              <div className="additional-filter">
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'all' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("all")}
                >
                  {t("sortAll.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isNew' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isNew")}
                >
                    {t("sortNew.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isTrend' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isTrend")}
                >
                 {t("sortTrend.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'discount' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("discount")}
                >
                  {t("sortDiscount.text")}
                </span>
              </div>
          </div>

          <div className="collections-container">
            {/* child component which shows filters */}
            <Sidebar filterShow={this.state.filterShow}  button = {this.state.button} CloseFilter = {this.CloseFilter}  />
            {/* if filters didn't match any element notFound text will show */}
            {this.state.notFound ? (
              <h3>{t("notFound.text")}</h3>
            ) : (
              <div
                className={` pt-2 ${
                  this.state.filterShow ? "collections" : "collections-small"
                } `}
              >
                {/* Skeleton for product card */}

                {this.state.isLoading && (
                  <ProductCardSkeleton cards={this.state.limit} />
                )}
                {/* iterating category elements by ProductCard component */}

                {this.state.products.map((col, index) => (
                  <Link key={index} to={`/product/${col.slug}`}>
                    <ProductCard
                      name={col.collection.nameUz + " " + col.name.slice(2, 6)}
                      articul={"#" + col.name}
                      price={col.price}
                      image={col.image ? col.image : col.collection.image}
                      isTrend={col.isTrend}
                      isNew={col.isNew}
                      isDiscount={col.discount}
                      index={index}
                      catName={col.collection.category_nameRu}
                    />
                  </Link>
                ))}
              </div>
            )}
          </div>
          {/* Pagination  */}
          <Paginate
            limit={limit}
            total={total}
            offset={this.offset}
            handlePageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(SearchOne);
